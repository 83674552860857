import React from "react"
import Sections from "../components/adopted-dogs/sections"
import DogFeeds from "../components/dog-feeds"
import Layout from "../components/layout"
import Seo from "../components/seo"
import InnerBanner from "../components/shared/inner-banner"
import banner from "../images/adopted-dogs-banner-bg.jpg"
const AdoptedDogsPage = () => {

  return <Layout colored_header={true}>
    <Seo title="Adopted Dogs" />
    <InnerBanner theme="white" image={banner} title="Adopted Dogs" subtitle="Dogs" />
    <Sections.Section1 />
       <DogFeeds type="adopted"/>
       <Sections.Section2/>
  </Layout>
}

export default AdoptedDogsPage