import React from 'react';
import "./sections.css"
import { Link } from "gatsby"


const Buttons = ({className}) => {

    return <div className={"adopted-buttons "+(className?className:"")}>
        <Link className="btn-primary btn-available-dogs" to="/available-dogs/">Available Dogs</Link>
        <Link className="btn-primary btn-coming-soon-dogs" to="/coming-soon-dogs/">Coming Soon Dogs</Link>
    </div>

}
const Section1 = () => {
    return <section className="adopted-sec1">
        <div className="content">
            <div className="text">
                <h2>These dogs have found their forever home….</h2>
                <p>Find your best friend by checking our <Link to="/available-dogs/">available dogs</Link> and those <Link to="/coming-soon-dogs/">coming soon</Link>!</p>
            </div>
            <Buttons />
        </div>
    </section>
}

const Section2 = () => {
    return <section className="adopted-sec1">
        <div className="content">

            <Buttons className={"mx-auto"} />
        </div>
    </section>
}

const Sections = {
    Section1,
    Section2
}

export default Sections